import React, { useEffect } from "react";
import App from "./App";
import { Redirect, BrowserRouter, Switch, Route } from "react-router-dom";

/**
 * Main Router for CLS modules
 * @author Kyaw Zin Soe
 * @datetime 1-Apr-2022
 */

/**
 * AppRouter component
 *
 * @param {object} props
 */
function AppRouter(props) {
  
  /**
   * return rendered view 
   *
   */
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route key={"route-1"} exact path="/">
            <Redirect to="/stocks/map" />
          </Route>
          {/*<Route exact path="/test/*" component={Test} exact></Route>*/}

          <Route key={"route-global"} path="/*" component={App} exact></Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default AppRouter;
