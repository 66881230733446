import React, { Suspense, useState, lazy, useEffect } from "react";
import { Loading } from "@wfp/ui";

/**
 * General module for loading modules
 * @author Kyaw Zin Soe
 * @datetime 10-Mar-2022
 */

/**
 * Loader component
 *
 * @param {object} props
 */
const Loader = (props) => {
  const [Component, setComponent] = useState(null);

  useEffect(() => {
    if (Component || !props.eUser) return;

    let _map = {
      stocks: "Stock",
      login: "Login",
      reset: "Reset",
      info: "Info",
      register: "Register",
      forgot: "Forgot",
      logistics: "Logistics",
      assessments: "Assessment",
      trackings: "Tracking",
      library: "Library",
      needs: "Need",
      admin: "Admin",
      emergencies: "Emergency",
      about: "About",
      terms: "Terms",
      help: "Help",
      test: "Test",
    };
    let _mod = "";
    if (props.route === "emergencies") {
        //emergencies sub routes
      if (props.sub === "info") {
        _mod = "Emergency/Info";
      } else if (props.sub === "form") {
        if (props.routes.length > 2) {
          if (props.routes[2] === "srf") {
            _mod = "Emergency/Srf";
          } else {
            _mod = "Emergency/Pl";
          }
        }
      } else {
        if (_map[props.route]) {
          _mod = _map[props.route];
        } else {
        }
      }
    } else {
        // other routes
      if (_map[props.route]) {
        _mod = _map[props.route];
      } else {
      }
    }
    if (_mod !== "") {
        //lazy loader
      setComponent(
        lazy(() =>
          import(
            "./pages/" +
              (props.eUser._isGuest() &&
              props.route !== "register" &&
              props.route !== "forgot" &&
              props.route !== "reset"
                ? "Login"
                : _mod) +
              (props.offline ? "Offline" : "")
          )
        )
      );
    }
  }, [props.eUser]);

  /**
   * return rendered view 
   *
   */
  return (
    <>
      {props.eUser.loaded ? (
        <Suspense
          fallback={<Loading active={true} withOverlay={true}></Loading>}
        >
          {Component ? <Component {...props} /> : <></>}
        </Suspense>
      ) : (
        <Loading active={true} withOverlay={true}></Loading>
      )}
    </>
  );
};

export default Loader;
